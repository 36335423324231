var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list" }, [_c("a-row", { staticClass: "list__title pt-2 pb-4" }, [_c("a-col", { staticClass: "background--white", attrs: { "span": 16 } }, [_c("h2", { staticClass: "mb-0 neutral-10--text" }, [_vm._v("Item Classifications")])]), _c("a-col", { staticClass: "background--white drop" }, [_c("div", { staticClass: "btn" }, [_c("a-button", { attrs: { "float": "right", "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v(" Add Item Classification ")])], 1)])], 1), _vm._m(0), _c("div", { staticClass: "list__table pr-5 pl-5 pb-4" }, [_c("div", { staticClass: "drop" }, [_c("label", [_vm._v("Search by")]), _c("a-input-search", { staticStyle: { "width": "300px" }, attrs: { "placeholder": "Search by Item" }, on: { "change": _vm.onItemCodeSearch } })], 1), _c("div", { staticClass: "d-flex justtify-content-between align-items-end" }, [_c("div", { staticClass: "filter mr-4" }, [_c("label", [_vm._v("Status")]), _c("a-select", { staticClass: "w-100", attrs: { "value": _vm.value } }, _vm._l(_vm.options, function(option) {
    return _c("a-select-option", { key: option.value, attrs: { "value": option.value }, on: { "click": _vm.onChange } }, [_c("a-icon", { attrs: { "type": option.iconType, "theme": option.iconTheme } }), _vm._v(" " + _vm._s(option.text) + " ")], 1);
  }), 1)], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-input", { key: "categoryID", attrs: { "data-source": _vm.listCategory, "source": "id", "default-active-first-option": "", "source-label": "categoryName", "rules": "required", "span": 24, "label": "Category", "placeholder": "Category" }, on: { "change": _vm.onChangeCategory } })], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-input", { key: "countryID", attrs: { "data-source": _vm.arrCountry, "source": "id", "source-label": "name", "rules": "required", "span": 24, "label": "Country", "placeholder": "Country", "default-active-first-option": "" }, on: { "change": _vm.onChangeCountry } })], 1)])]), _c("div", { staticClass: "pr-5 pl-5" }, [_c("a-table", { attrs: { "loading": _vm.isLoading, "bordered": "", "custom-row": _vm.customRow, "columns": _vm.columns, "data-source": _vm.resourceList, "pagination": _vm.pagination, "row-key": "id", "indent-size": -15 }, on: { "change": _vm.onTableChange }, scopedSlots: _vm._u([{ key: "Action", fn: function(text, record) {
    return _c("a", {}, [[_c("div", { staticClass: "drop mr-5 ml-5" }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "disabled": record.isInactive, "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onEditClick(record);
    } } })], 1)], 1), _c("div", { staticClass: "d-flex mr-5 ml-5" }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { class: record.isInactive ? "list__action-toggle-active inactive mr-5" : "list__action-toggle-active mr-5", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onToggleActiveClick(record);
    } } })], 1)], 1)]], 2);
  } }]) }, [_vm._t("action")], 2)], 1)], 1);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "ml-5 mt-3" }, [_c("div", { staticClass: "search" })]);
}];
render$1._withStripped = true;
var ListItemClassificationsData_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListItemClassificationsData",
  inject: ["apiUrl", "crud", "createRoute", "resourceName"],
  data() {
    return {
      hidePagination: false,
      hasSizeChanger: true,
      columns: [],
      listCategory: [],
      arrCountry: [],
      default: {},
      value: "false",
      options: [
        {
          text: "All",
          value: "all",
          iconType: "container",
          iconTheme: "twoTone"
        },
        {
          text: "Active",
          value: "false",
          iconType: "eye",
          iconTheme: "twoTone"
        },
        {
          text: "Inactive",
          value: "true",
          iconType: "eye-invisible",
          iconTheme: "outlined"
        }
      ]
    };
  },
  computed: {
    pagination() {
      const { page, count, pageSize } = this.crud.getPagination();
      return {
        current: page,
        showSizeChanger: this.hasSizeChanger,
        showTotal: this.showTotal,
        total: count,
        pageSize
      };
    },
    resourceList() {
      return this.crud.getList();
    },
    isLoading() {
      return this.$store.state[this.resourceName].isFetchingList;
    }
  },
  async created() {
    this.columns = [
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        sorter: true
      },
      {
        title: "Item No.",
        dataIndex: "itemCode",
        key: "itemCode",
        sorter: true
      },
      {
        title: "Item",
        dataIndex: "itemName",
        key: "itemName",
        sorter: true
      },
      {
        title: "Category",
        dataIndex: "categoryName",
        key: "categoryName",
        sorter: true
      },
      {
        title: "Portfolio",
        dataIndex: "portfolioName",
        key: "portfolioName",
        sorter: true
      },
      {
        title: "Action",
        dataIndex: "action",
        scopedSlots: { customRender: "Action" },
        width: "6%"
      }
    ];
    let datacountry = await this.axios.get(`${this.apiUrl}/shipment-closure/country-data`);
    this.arrCountry = datacountry.data.results;
    this.default = {
      id: 0,
      name: "All"
    };
    this.arrCountry.splice(0, 0, this.default);
    const { data } = await this.axios.get(`${this.apiUrl}/shipment-closure/category-data?fe=((IsInactive Eq 'false'))`);
    this.listCategory = data.results;
    this.default = {
      id: 0,
      isInactive: false,
      portfolioID: -1,
      categoryName: "All",
      portfolioName: "All",
      updatedAt: "0001-01-01T00:00:00Z",
      updatedBy: null
    };
    this.listCategory.splice(0, 0, this.default);
    this.crud.fetchList();
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList(this.customUrl, true, true, this.customQuery);
    },
    onChange(value) {
      this.value = value.key;
      if (this.value === "all") {
        this.crud.deleteFilter("IsInactive");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("IsInactive", {
        operator: "Eq",
        value: this.value
      });
      this.crud.getFilterQuery();
      this.crud.fetchList();
    },
    onChangeCategory(value) {
      if (value === 0) {
        this.crud.deleteFilter("categoryID");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("categoryID", {
        operator: "Eq",
        value
      });
      this.crud.getFilterQuery();
      this.crud.fetchList();
    },
    onItemCodeSearch(value) {
      if (value.currentTarget.value === "") {
        this.crud.deleteFilter("itemName");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("itemName", {
        operator: "Contains",
        value: value.currentTarget.value
      });
      this.crud.getFilterQuery();
      this.crud.fetchList();
    },
    onAddClick() {
      this.$router.push(this.createRoute);
    },
    onEditClick(record) {
      this.$router.push(`/shipment-closure/item-classification-data/${record.id}`);
    },
    onToggleActiveClick(record, customTitle = null, type = null) {
      let icon, okType, title;
      if (record.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = customTitle ? customTitle : "Do you want to Deactivate this item?";
        icon = type ? type : "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: () => {
          this.toggleActive(record);
        }
      });
    },
    async toggleActive(record) {
      await this.crud.submitPartialEntity({
        resourceName: this.resourceName,
        id: record.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !record.isInactive
          }
        ]
      });
      this.crud.fetchList();
    },
    onChangeCountry(value) {
      if (value == "0") {
        this.crud.deleteFilter("countryID");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("countryID", {
        operator: "Eq",
        value
      });
      this.crud.getFilterQuery();
      this.crud.fetchList();
    },
    onTableChange(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      this.crud.setPagination(__spreadProps(__spreadValues({}, this.crud.getPagination()), {
        page: current,
        pageSize
      }));
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.crud.fetchList();
    },
    customRow(record) {
      if (record.isInactive || "isActive" in record && !record.isActive) {
        return { class: "list__item--inactive" };
      }
      return {};
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6fd1588b", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/ItemClassificationData/ListItemClassificationsData/ListItemClassificationsData.vue";
var ListItemClassificationsData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-item-classifications-data" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "shipment-closure.item-classification-data", "api-url": _vm.apiUrl, "create-route": "/shipment-closure/item-classification-data/create", "edit-route": "/shipment-closure/item-classification-data/:id" } }, [_c("list-item-classifications-data")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListItemClassificationsData
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "item-classification-data",
          title: "Item Classification",
          path: "/shipment-closure/item-classification-data"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/ItemClassificationData/ListItemClassificationsData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
